<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "SetScript.EditSetScript",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="detailRequestEnd && setScriptData.isOk"
        @methodSave="onSubmit('btn-save')"
        :isNewRecord="false"
      />
    </div>
  </div>
  <SetLoader v-if="!detailRequestEnd" />
  <div v-if="errors.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in errors" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <form
    v-show="detailRequestEnd && setScriptData.isOk"
    class="form-set-script-edit pe-2 ps-2"
    novalidate
  >
    <div class="row row-cols-1">
      <div class="col col-6">
        <label for="scriptType" class="form-label required">{{
          $t(
            "SetScript.ScriptType",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <FormSelect
          type="SelectWithLocalData"
          :selectedData="selectedSetScript"
          :allowEmpty="false"
          :data="scriptTypeData"
          @onChange="onChangeForScriptType"
        />
      </div>
      <div class="col col-4">
        <label for="IsRunAfterPageLayoutLoaded" class="form-label">{{
          $t(
            "SetScript.IsRunAfterPageLayoutLoaded",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="IsRunAfterPageLayoutLoaded"
            v-model="setScriptData.isRunAfterPageLayoutLoaded"
          />
        </div>
      </div>
      <div class="col col-2">
        <label for="IsActive" class="form-label">{{
          $t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="IsActive"
            v-model="setScriptData.isActive"
          />
        </div>
      </div>
    </div>
    <div class="row row-cols-1 mt-3">
      <div class="col col-md-12">
        <label for="Description" class="form-label required">{{
          $t(
            "SetScript.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}</label>
        <textarea
          name="Description"
          class="form-control"
          id="Description"
          v-model="setScriptData.description"
          :spellcheck="this.$isTextSpellCheck"
          rows="3"
          required
        ></textarea>
      </div>
    </div>
    <div class="row row-cols-1 mt-3 mb-3">
      <div class="col-md-12">
        <label for="ScriptCode" class="form-label">
          {{
            $t(
              "SetScript.ScriptCode",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
          <div class="alert-info alert p-0 float-end ms-1 ps-1 pe-1 mb-0">
            {{
              $t(
                "BaseModelFields.SwitchFullScreenMode",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </label>
        <v-ace-editor
          v-model:value="setScriptData.script"
          lang="javascript"
          theme="monokai"
          @init="editorInit"
          :options="options"
          id="scriptEditor"
          style="height: 300px"
        />
      </div>
    </div>
  </form>
  <ScriptHelperModal />
</template>
<script>
import $ from "jquery";
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/src-noconflict/theme-monokai";

import "ace-builds/src-noconflict/mode-javascript";

import "ace-builds/src-noconflict/ext-language_tools";
import beautifier from "ace-builds/src-noconflict/ext-beautify";

import "ace-builds/src-noconflict/snippets/javascript";

import "ace-builds/src-noconflict/ext-searchbox";

import ace from "ace-builds";

import workerJsUrl from "file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js";
ace.config.setModuleUrl("ace/mode/javascript_worker", workerJsUrl);

import ScriptHelperModal from "@/components/custom/script-helper/ScriptHelperModal.vue";

export default {
  name: "CustomObjectEditSetScript",
  components: {
    VAceEditor,
    ScriptHelperModal,
  },
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      script: "",
      scriptEditor: null,
      selectedSetScript: [],
      setScriptData: {},
      detailRequestEnd: false,
      scriptTypeData: [
        {
          key: "1",
          value: this.$t(
            "SetScript.ScriptTypeNewRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "SetScript.ScriptTypeEditRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "SetScript.ScriptTypeListRecords",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "SetScript.ScriptTypeDetailRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "5",
          value: this.$t(
            "SetScript.ScriptTypeKanban",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "6",
          value: this.$t(
            "SetScript.ScriptTypeCalendar",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "7",
          value: this.$t(
            "SetScript.ScriptTypeMap",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "8",
          value: this.$t(
            "SetScript.ScriptTypeTree",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
    };
  },
  methods: {
    onChangeForScriptType: function (selected) {
      this.setScriptData.view = selected.key;
    },
    getConfigurationTypeName(valueTypeId) {
      var text = this.$t(
        "SetScript.ScriptTypeNewRecord",
        {},
        { locale: this.$store.state.activeLang }
      );
      if (valueTypeId == 2) {
        text = this.$t(
          "SetScript.ScriptTypeEditRecord",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 3) {
        text = this.$t(
          "SetScript.ScriptTypeListRecords",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 4) {
        text = this.$t(
          "SetScript.ScriptTypeDetailRecord",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 5) {
        text = this.$t(
          "SetScript.ScriptTypeKanban",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 6) {
        text = this.$t(
          "SetScript.ScriptTypeCalendar",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 7) {
        text = this.$t(
          "SetScript.ScriptTypeMap",
          {},
          { locale: this.$store.state.activeLang }
        );
      } else if (valueTypeId == 8) {
        text = this.$t(
          "SetScript.ScriptTypeTree",
          {},
          { locale: this.$store.state.activeLang }
        );
      }
      return text;
    },
    getSetScript() {
      this.errors = [];
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-SetScriptGet?id={0}&coId={1}",
            this.$route.params.scriptId,
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.setScriptData = response.data;
          this.detailRequestEnd = true;
          if (response.data.isOk) {
            if (!String.isNullOrWhiteSpace(response.data.view)) {
              this.selectedSetScript.push({
                key: response.data.view.toString(),
                value: this.getConfigurationTypeName(response.data.view),
              });
            }
            if (!String.isNullOrWhiteSpace(response.data.script)) {
              this.script = response.data.script;
            }

            setTimeout(() => {
              this.scriptEditor = ace.edit("scriptEditor");
              this.scriptEditor.commands.addCommand(
                this.$root.ace.commands.fullScreen
              );

              beautifier.beautify(this.scriptEditor.session);
            }, 500);

            this.setScriptData.customObjectFormulaName =
              this.$store.getters._customObjectDto.formulaName;
          } else {
            this.errors.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onSubmit(buttonName) {
      var form = $(".form-set-script-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      beautifier.beautify(this.scriptEditor.session);

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.setScriptData.script = this.setScriptData.script.toString();
      // if (!String.isNullOrWhiteSpace(this.setScriptData.script)) {
      //   this.setScriptData.script = this.script.toString();
      // }
      this.$prodGatewayAxios
        .post("/Lcdp-SetScriptEdit", { ...this.setScriptData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push(
              "/CustomObject/SetScripts/" + this.$route.params.customObjectId
            );
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getSetScript();
  },
};
</script>
